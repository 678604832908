<template>
  <div class="manage-clients-detail">
    <div class="container fluid">
      <legal-client-detail
        :getClientAPI="clientAPI.getClient"
        :markWithLADateAPI="clientAPI.markWithLA"
        :markWithoutLADateAPI="clientAPI.markWithoutLA"
        :setLADateAPI="clientAPI.setLADate"
        :setSPADateAPI="clientAPI.setSPADate"
        :signLAAPI="clientAPI.signLA"
        :signSPAAPI="clientAPI.signSPA"
        :payStampLAAPI="clientAPI.payStampLA"
        :payStampSPAAPI="clientAPI.payStampSPA"
        :downloadFileAPI="downloadFile"
      ></legal-client-detail>
    </div>
  </div>
</template>

<script>
import LegalClientDetail from "@/components/GlobalComponents/Shared/Lawyer/LegalClientDetail";
import { client as clientAPI } from "@/api";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    LegalClientDetail
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      clientAPI,
      downloadFile
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.manage-clients-detail {
}
</style>
